import React from 'react'
import Seo from '../../../components/seo/seo'
import { Container } from "react-bootstrap"
import '../../styles/index.scss';
import '../../styles/internal-pages.css';
import { StructuredText } from "react-datocms";

const EachBlog = ({ pageContext }) => {

    const data = pageContext.data;

    console.log("data", data)

    return (
        <Container>
            <div className='mt-5 blog-post'>
                <h1>{data.postTitle}</h1>
                <p>by {data.author} | {data.publishDate}</p>
                <img alt="blog img"  className='mb-5' src={data.image.url} />
                <StructuredText data={data.bodyPost.value} />
            </div>
        </Container>
    )
}

export default EachBlog

export const Head = ({ data }) => (
    <>
      <Seo title="Blogs"
            description="Collect • Play • Trade"
            image="https://www.datocms-assets.com/80384/1664752762-dtc-logo-2021.png?auto=format"
      />
    </>
  )